<template>
  <div id="about">
    <div class="wrapper w-100 container mb-5 mt-3 m-auto d-flex align-items-center">
      <img class="main" src="@/assets/img/about_one.png" alt="">
      <img class="mobile" src="@/assets/img/mainmobile.png" alt="">
      <div class="title">
        <h3>ADWAY - ваше будущее начинается здесь и сейчас</h3>
        <p>Присоединяйтесь к нам в путешествии к знаниям, развитию и успеху!</p>
        <router-link to="/about#application"><button>Получить консультацию</button></router-link>
      </div>
    </div>
    <div class="wrapper_one container py-5">
      <div class="inner_card py-5">
        <div class="inner_content">
          <h1>История</h1>
          <p>Наша онлайн площадка является результатом долгого пути развития, начиная с нашей первоначальной платформы "uchitelya.kz". Наш опыт в ведении образовательных ресурсов в Казахстане вдохновил нас создать новую онлайн площадку, предлагающий высококачественное образование.</p>
        </div>
        <div class="w-50">
          <img style="margin-left: auto;" src="@/assets/img/about_three.png" alt="">
        </div>
      </div>
      <div class="inner_card py-5">
        <div class="w-50">
          <img style="margin-left: auto;" src="@/assets/img/about_six.png" alt="">
        </div>
        <div class="inner_content">
          <h1>Миссия и Ценности</h1>
          <p>Мы стремимся предоставить доступное, качественное и инновационное образование, формируя будущее лидеров и профессионалов. Наши ценности включают в себя стремление к знаниям, разнообразию и развитию каждого студента.</p>
        </div>
      </div>
      <div class="inner_card py-5">
        <div class="inner_content">
          <h1>Образовательные Программы</h1>
          <p>Наш площадка предлагает широкий спектр образовательных программ, адаптированных к современным требованиям рынка труда. Мы гордимся тем, что предоставляем студентам возможность выбора среди инновационных курсов, поддерживаемых опытными преподавателями.</p>
        </div>
        <div class="w-50">
          <img style="margin-left: auto;" src="@/assets/img/about_seven.png" alt="">
        </div>
      </div>
      <div class="inner_card py-5" style="position:relative;">
        <div class="w-50">
          <img src="@/assets/img/about_4.png" alt="" style="position: absolute; top: -57px;">
        </div>
        <div class="inner_content">
          <h1>Инновации и Технологии</h1>
          <p>Мы осуществляем обучение с использованием передовых технологий, предлагая уникальные онлайн-ресурсы, интерактивные занятия и современные методы обучения. Это обеспечивает максимальный комфорт и эффективность учебного процесса для наших студентов.</p>
        </div>
      </div>
      <div class="inner_card py-5">
        <div class="inner_content">
          <h1>Сообщество и Сеть</h1>
          <p>Мы создаем образовательное сообщество, где студенты могут обмениваться опытом, создавать контакты и расширять свои горизонты. Наш университет поддерживает взаимодействие между студентами и преподавателями, способствуя общественной активности и креативному обмену идеями.</p>
        </div>
        <div class="w-50">
          <img style="margin-left: auto;" src="@/assets/img/about_five.png" alt="">
        </div>
      </div>
    </div>
    <div class="wrapper_one_mobile container">
      <div class="inner_card py-2">
        <div class="inner_content">
          <h1>История</h1>
          <p>Наша онлайн площадка является результатом долгого пути развития, начиная с нашей первоначальной платформы "uchitelya.kz". Наш опыт в ведении образовательных ресурсов в Казахстане вдохновил нас создать новую онлайн площадку, предлагающий высококачественное образование.</p>
        </div>
        <div class="w-50">
          <img style="margin-left: auto;" src="@/assets/img/about_three_mobile.png" alt="">
        </div>
      </div>
      <div class="inner_card py-2">
        <div class="inner_content">
          <h1>Миссия и Ценности</h1>
          <p>Мы стремимся предоставить доступное, качественное и инновационное образование, формируя будущее лидеров и профессионалов. Наши ценности включают в себя стремление к знаниям, разнообразию и развитию каждого студента.</p>
        </div>
        <div class="w-50">
          <img style="margin-left: auto;" src="@/assets/img/about_six.png" alt="">
        </div>
      </div>
      <div class="inner_card py-2">
        <div class="inner_content">
          <h1>Образовательные Программы</h1>
          <p>Наш площадка предлагает широкий спектр образовательных программ, адаптированных к современным требованиям рынка труда. Мы гордимся тем, что предоставляем студентам возможность выбора среди инновационных курсов, поддерживаемых опытными преподавателями.</p>
        </div>
        <div class="w-50">
          <img style="margin-left: auto;" src="@/assets/img/about_seven.png" alt="">
        </div>
      </div>
      <div class="inner_card py-2" style="position:relative;">
        <div class="inner_content">
          <h1>Инновации и Технологии</h1>
          <p>Мы осуществляем обучение с использованием передовых технологий, предлагая уникальные онлайн-ресурсы, интерактивные занятия и современные методы обучения. Это обеспечивает максимальный комфорт и эффективность учебного процесса для наших студентов.</p>
        </div>
        <div class="w-50">
          <img src="@/assets/img/about_4.png" alt="" style="margin-left: auto;">
        </div>
      </div>
      <div class="inner_card py-2">
        <div class="inner_content">
          <h1>Сообщество и Сеть</h1>
          <p>Мы создаем образовательное сообщество, где студенты могут обмениваться опытом, создавать контакты и расширять свои горизонты. Наш университет поддерживает взаимодействие между студентами и преподавателями, способствуя общественной активности и креативному обмену идеями.</p>
        </div>
        <div class="w-50">
          <img style="margin-left: auto;" src="@/assets/img/about_five.png" alt="">
        </div>
      </div>
    </div>
    <ContactForm/>
  </div>
</template>

<script>
  import ContactForm from "../../../components/ru/ContactForm";

  export default {
    name: "aboutPage",
    components: {
      ContactForm,
    },
    data(){
    },
    methods: {

    }
  }
</script>

<style lang="scss">
@import './about.scss';
</style>