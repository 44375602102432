<template>
  <div id="landing">
    <div class="wrapper w-100 container mb-5 mt-3 m-auto d-flex align-items-center">
      <img class="main" src="@/assets/img/banners/it-systems.png" alt="">
      <img class="mobile" src="@/assets/img/mainmobile.png" alt="">
      <div class="title">
        <p class="top_edu">
          <span>Магистратура</span>
          <span>Бакалавриат</span>
        </p>
        <h3>Ақпараттық<br/>жүйелер</h3>
        <p style="border-bottom: 2px solid #FFA51E;width:80px;">6B06106</p>
        <router-link to="#application"><button>Кеңес алу</button></router-link>
      </div>
    </div>
    <div class="container px-5">
      <p><b class="blue_bold">Білім беру бағдарламасының мақсаты</b> “Ақпараттық жүйелер” – ақпараттық жүйелер мен технологияларды әртүрлі салаларда қолдану үшін жоғары білікті, бәсекеге қабілетті мамандарды жан-жақты және сапалы даярлау.</p>

      <p><b class="blue_bold">Кәсіби қызметтің міндеттері:</b></p>
      <ul>
        <li>гуманитарлық, экологиялық, әлеуметтік-құқықтық, экономикалық ғылымдардың, физика мен жоғарғы математиканың білімдерін барлық қызмет салаларында қолдану;</li>
        <li>деректер құрылымдарын және есептерді шешу алгоритмдерін әзірлеу, олардың бағдарламалық іске асырылуын орындау, ақпараттық жүйелер мен желілерді қолдау;</li>
        <li>ақпараттық жүйелердегі әртүрлі процестерді жобалауда математикалық және/немесе имитациялық модельдерді және әдістерді әзірлеу және қолдану;</li>
        <li>ақпараттық жүйелер дерекқорларын жобалау және басқару;</li>
        <li>компьютерлік жүйелер мен желілерді әкімшілендіру негіздерін меңгеру;</li>
        <li>ақпараттық жүйенің архитектурасын жобалау;</li>
        <li>жүйелік, құралдық және қолданбалы бағдарламалық қамтамасыз етуді таңдауды дәлелдеу;</li>
        <li>эксперттік, интеллектуалдық және роботтық жүйелерді әзірлеу, олардың жобалауында оңтайлы шешімдерді таңдау;</li>
        <li>ақпараттық жүйелердің эргономикалық дизайнын жобалау;</li>
        <li>мобильді, желілік қосымшаларды әзірлеу үшін қазіргі заманғы құралдарды және орталарды меңгеру;</li>
        <li>ақпараттық қауіпсіздік және жүйелердің сенімділігі әдістерін жобалау және әзірлеу;</li>
        <li>ұйымның ақпараттандыру қажеттіліктерін анықтау, ақпараттық процестер мен жүйелерге қойылатын талаптарды қалыптастыру, оңтайлы шешімді ұсыну.</li>
      </ul>

      <p><b class="blue_bold">Жұмысқа орналасу мүмкіндіктері:</b></p>
      <ul>
        <li>жетекші компьютерлік компанияларда («Kaspi», «Kolesa», «OneTech»);</li>
        <li>телекоммуникация және байланыс саласында («Казахтелеком», «К-Сеll», «Beeline», «Dalacom», «Neo»);</li>
        <li>желілік әкімшілендіру, дерекқорларды басқару, ақпаратты қорғау, бағдарламалық жасақтама әзірлеу, есептеу техникасымен жұмыс істеу, микропроцессорлық жүйелер, компьютерлік және желілік технологиялар саласында қызмет көрсететін бөлімдерде (Seiko Epson Со, Samba Construction, ТОО «OMEGA.KZ», «Alfa technologies», «ALTERNATE», TOO «N-Com», «Newinttech», BP Solution және басқа да алдыңғы қатарлы, табысты дамып келе жатқан ұйымдар).</li>
      </ul>
    </div>
    <div class="wrapper_four container py-5 mt-3" id="profession" style="height: auto;">
      <Partners/>
    </div>
    <ContactForm/>
  </div>
</template>

<script>
  import ContactForm from "../../../../components/kz/ContactForm";
  import Partners from "../../../../components/kz/Partners";

  export default {
    name: "ItSystemsPage",
    components: {
      ContactForm,
      Partners
    },
    data(){
    },
    methods: {

    }
  }
</script>

<style lang="scss">
@import './it-systems.scss';
@import '../../landing/landing.scss';
</style>