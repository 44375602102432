<template>
  <div id="landing">
    <div class="wrapper w-100 container mb-5 mt-3 m-auto d-flex align-items-center">
      <img class="main" src="@/assets/img/banners/it-security.png" alt="">
      <img class="mobile" src="@/assets/img/mainmobile.png" alt="">
      <div class="title">
        <p class="top_edu">
          <span>Магистратура</span>
          <span>Бакалавриат</span>
        </p>
        <h3>Ақпараттық қауіпсіздік</h3>
        <p style="border-bottom: 2px solid #FFA51E;width:80px;">6B06301</p>
        <router-link to="#application"><button>Кеңес алу</button></router-link>
      </div>
    </div>
    <div class="container px-5">
      <p><b class="blue_bold">Білім беру бағдарламасының мақсаты</b> "Ақпараттық қауіпсіздік" – ақпараттық қауіпсіздік саласында жоғары білікті мамандарды дайындау, олар кез келген күрделі тапсырмаларды шеше алады, соның ішінде:</p>
      <ul>
        <li>қорғау,</li>
        <li>ақпаратты сенімді сақтау,</li>
        <li>ақпаратты беру және өңдеу,</li>
      </ul>
      <p>математика және ақпараттық технологиялар саласындағы ең озық жетістіктерге сүйене отырып, заманауи аппараттық-бағдарламалық құралдарды қолдана отырып.</p>

      <p>Ақпараттық қауіпсіздік маманы келесі қызметтерді атқарады:</p>
      <ul>
        <li>ақпаратты кешенді қорғауды қамтамасыз етеді,</li>
        <li>қолданыстағы қауіпсіздік жүйесіне аудит жүргізеді,</li>
        <li>ақпараттық тәуекелдерді талдайды,</li>
        <li>ақпараттық қауіпсіздік жүйесінің мониторингін жүргізеді.</li>
      </ul>

      <p>және осыған сәйкес компанияның ақпараттық қауіпсіздігін қамтамасыз ету бойынша шараларды әзірлейді және енгізеді.</p>

      <p><b class="blue_bold">Кәсіби қызметтің міндеттері:</b></p>
      <ul>
        <li>рұқсатсыз кіруден қорғау жүйелерін енгізу және қолдау;</li>
        <li>жеке деректерді қорғау;</li>
        <li>ақпаратты қорғаудың корпоративтік кешенді жүйесін жобалау;</li>
        <li>ақпаратты қорғаудың көпдеңгейлі жүйесін құру (логиндер мен құпиясөздер, телефон нөмірлері, саусақ ізі, көздің тор қабығы арқылы сәйкестендіру және т.б.);</li>
        <li>жүйе элементтерін (сайт, қызмет, автоматтандырылған жүйе) осалдықтарға тексеру және анықталған ақауларды жою;</li>
        <li>ақпаратты физикалық қорғау жүйелерін жобалау;</li>
        <li>бұзылулардың алдын алу және олардың салдарын жою;</li>
        <li>ақпаратты қорғау бойынша жаңа регламенттерді әзірлеу және енгізу;</li>
        <li>жүйе пайдаланушыларымен жұмыс істеу, қорғау шараларын сақтау маңыздылығын түсіндіру;</li>
        <li>құжаттаманы жүргізу және IT-жүйелердің қауіпсіздігі бойынша есептерді дайындау;</li>
        <li>желі параметрлерін баптау, қателер мен ықтимал осалдықтарды болжау, қосылымдарды бақылау технологияларын іске қосу;</li>
        <li>кодтың архитектурасы мен дайын кодты зерттеу, осалдықтарды анықтау және оларды жою бойынша техникалық тапсырма құрастыру.</li>
      </ul>

      <p><b class="blue_bold">Жұмысқа орналасу мүмкіндіктері:</b></p>
      <ul>
        <li>банктік салада (Kaspi Bank, Банк ЦентрКредит, Halyk Bank, ДО АО Банк ВТБ Қазақстан және т.б.);</li>
        <li>телекоммуникация және байланыс саласында - ҚазақТелеком, КазТелепорт, К-Сеll, Beeline, Dalacom;</li>
        <li>жетекші компьютерлік компанияларда (ЦАРКА, Alsi, Logicom, RealSoft, «ICORE-Integration»);</li>
        <li>мемлекеттік құрылымдарда (НИТ, ІІМ, Салық комитеті, ҰҚК);</li>
        <li>ақпаратты қорғау, желілік әкімшілендіру, деректер базасын әкімшілендіру, бағдарламалық жасақтаманы әзірлеу салаларында.</li>
      </ul>
    </div>
    <div class="wrapper_four container py-5 mt-3" id="profession" style="height: auto;">
      <Partners/>
    </div>
    <ContactForm/>
  </div>
</template>


<script>
  import ContactForm from "../../../../components/kz/ContactForm";
  import Partners from "../../../../components/kz/Partners";

  export default {
    name: "ItSecurityPage",
    components: {
      ContactForm,
      Partners
    },
    data(){
    },
    methods: {

    }
  }
</script>

<style lang="scss">
@import './it-security.scss';
@import '../../landing/landing.scss';
</style>