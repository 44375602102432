<template>
  <div id="landing">
    <div class="wrapper w-100 container mb-5 mt-3 m-auto d-flex align-items-center">
      <img class="main" src="@/assets/img/banners/it-systems.png" alt="">
      <img class="mobile" src="@/assets/img/mainmobile.png" alt="">
      <div class="title">
        <h3>Переход на дистанционную форму обучения</h3>
        <router-link to="#application"><button>Получить консультацию</button></router-link>
      </div>
    </div>
    <div class="container px-5">
      <p>Переход на дистанционную форму обучения в рамках очной формы обучения (на платном сервисе 30&nbsp;000 тенге в месяц), для студентов платного отделения и обладателей грантов с сохранением гранта.<br /> &nbsp; &nbsp; &nbsp;<br /> <strong>Преимущества дистанционного обучения:</strong></p>
      <ul>
        <li>Экономия на транспортных расходах;</li>
        <li>Снижение затрат на аренду жилья для иногородних студентов;</li>
        <li>Возможность совмещать учебу с работой;</li>
        <li>Экономия на продуктах питания.</li>
      </ul>
      <div class="d-none">
        <p><strong>Инструкция</strong></p>
        <ol>
          <li>Оплатить 30&nbsp;000 тенге (ежемесячно)</li>
        </ol>
        <p>Вариант 1: <a href="https://pay.kaspi.kz/pay/8sh0l6pw">Ссылка для оплаты платного сервиса</a></p>
        <p>Вариант 2: Kaspi QR</p>
        <img src="@/assets/img/ttdl-qr.gif" alt="">
        <ol start="2">
          <li>Заполнить заявление и подписать &ndash; <a href="/theme/space/Obrazets-zayavleniya-na-GOG.docx">скачать шаблон</a></li>
          <li>Передать заявление и чек Төлеген Сабыржану Рысбекұлы в Офис Регистратор (ОР)</li>
        </ol>
      </div>
      <div class="alert alert-danger">Прием заявок закрыт</div>

    </div>
    <FeedBackForm/>
  </div>
</template>

<script>
  import FeedBackForm from "../../../../components/ru/FeedBackForm";

  export default {
    name: "TTDL",
    components: {
      FeedBackForm,
    },
    data(){
    },
    methods: {

    }
  }
</script>

<style lang="scss">
@import './ttdl.scss';
@import '../../landing/landing.scss';
</style>