import { createRouter, createWebHashHistory } from 'vue-router';
import About from '@/pages/ru/aboutPage/about.vue';
import Home from '@/pages/ru/landing/landing.vue';
import ItSecurity from "@/pages/ru/program/it-security/it-security";
import ItSystems from "@/pages/ru/program/it-systems/it-systems";
import MathCompModeling from "@/pages/ru/program/math-comp-modeling/math-comp-modeling";
import ComputerScience from "@/pages/ru/program/computer-science/computer-science";
import TTDL from "@/pages/ru/other/ttdl/ttdl";

import About_kz from '@/pages/kz/aboutPage/about.vue';
import Home_kz from '@/pages/kz/landing/landing.vue';
import ItSecurity_kz from "@/pages/kz/program/it-security/it-security";
import ItSystems_kz from "@/pages/kz/program/it-systems/it-systems";
import MathCompModeling_kz from "@/pages/kz/program/math-comp-modeling/math-comp-modeling";
import ComputerScience_kz from "@/pages/kz/program/computer-science/computer-science";
import TTDL_kz from "@/pages/kz/other/ttdl/ttdl";


const routes = [
    //ru
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        component: About
    },
    {
        path: '/program/it-security',
        name: 'ItSecurity',
        component: ItSecurity
    },
    {
        path: '/program/it-systems',
        name: 'ItSystems',
        component: ItSystems
    },
    {
        path: '/program/math-comp-modeling',
        name: 'MathCompModeling',
        component: MathCompModeling
    },
    {
        path: '/program/computer-science',
        name: 'ComputerScience',
        component: ComputerScience
    },
    {
        path: '/other/ttdl',
        name: 'TTDL',
        component: TTDL
    },
    //kz
    {
        path: '/kz/',
        name: 'HomeKZ',
        component: Home_kz
    },
    {
        path: '/kz/about',
        name: 'AboutKZ',
        component: About_kz
    },
    {
        path: '/kz/program/it-security',
        name: 'ItSecurityKZ',
        component: ItSecurity_kz
    },
    {
        path: '/kz/program/it-systems',
        name: 'ItSystemsKZ',
        component: ItSystems_kz
    },
    {
        path: '/kz/program/math-comp-modeling',
        name: 'MathCompModelingKZ',
        component: MathCompModeling_kz
    },
    {
        path: '/kz/program/computer-science',
        name: 'ComputerScienceKZ',
        component: ComputerScience_kz
    },
    {
        path: '/kz/other/ttdl',
        name: 'TTDLKZ',
        component: TTDL_kz
    },
];

export const router = createRouter({
    history: createWebHashHistory(),
    routes
});
