<template>
  <div id="landing">
    <div class="wrapper w-100 container mb-5 mt-3 m-auto d-flex align-items-center">
      <img class="main" src="@/assets/img/main_one.png" alt="">
      <img class="mobile" src="@/assets/img/mainmobile.png" alt="">
      <div class="title">
        <h3>Обучайся в Satbayev University в режиме онлайн с Adway.edu.kz</h3>
        <p>Присоединяйтесь к нам в путешествии к знаниям, развитию и успеху!</p>
        <router-link to="/#application" class="nav-link"><button>Получить консультацию</button></router-link>
      </div>
    </div>
    <div class="wrapper_one m-auto container" id="why">
      <div class="title">
        <h1>Преимущества</h1>
        <hr>
      </div>
      <div class="wrap w-100 d-flex justify-content-between align-items-center">
        <div class="inner_card">
          <img src="@/assets/img/icon_4.png" alt="$">
          <div class="pt-3">
            <h3>Гибкость</h3>
            <p>Вы можете учиться в любое время и из любой точки мира, где есть доступ к интернету. Это особенно удобно для работающих людей, родителей и жителей удаленных регионов.</p>
          </div>
        </div>
        <div class="inner_card">
          <img src="@/assets/img/icon_12.png" alt="$">
          <div class="pt-3">
            <h3>Доступность</h3>
            <p>Вы можете пересматривать лекции и учебные материалы в любое удобное для Вас время, имея доступ к курсам из любой точки мира 24/7.</p>
          </div>
        </div>
        <div class="inner_card">
          <img src="@/assets/img/icon_7.png" style="width:64px;height:64px;" alt="$">
          <div class="pt-3">
            <h3>Удобство</h3>
            <p>Вы можете совмещать обучение с работой (днем ходить на работу, а вечером подключаться к вебинарам).</p>
          </div>
        </div>
        <div class="inner_card">
          <img src="@/assets/img/icon_10.png" style="width:64px;height:64px;" alt="$">
          <div class="pt-3">
            <h3>Государственный диплом</h3>
            <p>Получить диплом Satbayev University!</p>
          </div>
        </div>
      </div>

      <div class="mobile">
        <swiper
            :modules="modules"
            :slides-per-view="1"
            :space-between="20"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            :loop="true"
            :navigation="{
              prevEl: prev1,
              nextEl: next1,
            }"
            class="mySwiper mySwiper1"
        >
          <swiper-slide>
            <div class="inner_card">
              <img src="@/assets/img/Icon1.png" alt="$">
              <div class="pt-3">
                <h3>Качество образования</h3>
                <p>Мы предлагаем высококачественные образовательные программы, созданные экспертами, для получения актуальных знаний и навыков для успешной карьеры студентами.</p>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="inner_card">
              <img src="@/assets/img/Icon2.png" alt="$">
              <div class="pt-3">
                <h3>Гибкость и доступность</h3>
                <p>Платформа "Adway.edu.kz" предлагает гибкий график обучения, доступ к материалам 24/7 и индивидуальный темп для достижения образовательных целей студентами.</p>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="inner_card">
              <img src="@/assets/img/Icon3.png" alt="$">
              <div class="pt-3">
                <h3>Технологические ресурсы</h3>
                <p>Мы используем современные технологии и методики, включая вебинары и виртуальные классы, для обогащения обучения студентов.</p>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="inner_card">
              <img src="@/assets/img/Icon4.png" alt="$">
              <div class="pt-3">
                <h3>Персональная поддержка</h3>
                <p>Наша платформа обеспечивает персональную поддержку и ресурсы для успеха и развития студентов в образовательном процессе.</p>
              </div>
            </div>
          </swiper-slide>

          <!-- Навигационные стрелки -->
          <div ref="prev1" class="swiper-button-next"></div>
          <div ref="next1" class="swiper-button-prev"></div>
        </swiper>
      </div>
    </div>

    <div class="wrapper_two container pt-5 mb-3" id="process">
      <div class="title mb-5">
        <h1>Процесс зачисления</h1>
        <hr>
      </div>
      <div class="wrap w-100 d-flex justify-content-between align-items-center">
        <div class="inner_card">
          <div class="card_inner">
            <div>
              <h1>1</h1>
            </div>
            <div class="pt-3">
              <h3>Оставить заявку</h3>
              <p>Заполните форму и начните обучение у нас</p>
            </div>
          </div>
        </div>
        <div class="inner_card">
          <div class="card_inner">
            <div>
              <h1>2</h1>
            </div>
            <div class="pt-3">
              <h3>Cвязь с менеджером</h3>
              <p>Наши менеджеры помогут ответить на ваши вопросы</p>
            </div>
          </div>
        </div>
        <div class="inner_long_card">
          <img src="@/assets/img/main_two.png" alt="" style="position: absolute; right: 0; z-index: 10; top: 10px">
          <div class="card_inner">
            <div>
              <h1>3</h1>
            </div>
            <div class="pt-3">
              <h3>Вы обучаетесь в Satbayev University</h3>
              <p>Добро пожаловать в Satbayev University!</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="wrapper_three container" id="applicants">
      <div class="title">
        <h1>Требования от абитуриента</h1>
        <hr>
      </div>
      <div class="pt-3 d-flex">
        <div class="icon" style="width: 40%">
          <img src="@/assets/img/main_five.png" alt="">
        </div>
        <div class="inner_card">
          <div class="card_content">
            <div>
              <img src="@/assets/img/main_six.png" alt="">
            </div>
            <p>Аттестат об общем среднем образовании или
              диплом технического образования</p>
          </div>
          <div class="card_content">
            <div>
              <img src="@/assets/img/main_six.png" alt="">
            </div>
            <p>Копия документа, удостоверяющего
              личность (3 шт) </p>
          </div>
          <div class="card_content">
            <div>
              <img src="@/assets/img/main_six.png" alt="">
            </div>
            <p>Удостоверение о прописке к призывному участку для юношей копия (2 шт.)</p>
          </div>
          <div class="card_content">
            <div>
              <img src="@/assets/img/main_six.png" alt="">
            </div>
            <p>Фото 3х4 см</p>
          </div>
          <div class="card_content">
            <div>
              <img src="@/assets/img/main_six.png" alt="">
            </div>
            <p>Сертификат ЕНТ</p>
          </div>
        </div>
      </div>
      <div class="container m-auto long-img pt-2" id="bachelor_magistr">
        <swiper
            :modules="modules"
            :slides-per-view="1"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            :loop="true"
            :navigation="{
              prevEl: prev2,
              nextEl: next2,
            }"
            class="mySwiper mySwiper2"
        >
          <swiper-slide>
            <router-link to="/program/computer-science">
              <img class="w-100 long-img-main" src="@/assets/img/main_three.png" alt="">
              <div class="long-img-mobile">
                <div class="icons">
                  <div class="inner_icons"><span>Магистратура</span></div>
                  <div class="inner_icons"><span>Бакалавр</span></div>
                </div>
                <div class="title">
                  <h3>Computer science</h3>
                  <p>Программа Computer Science открывает перед выпускниками множество карьерных возможностей в таких областях как IT, финансы, здравоохранение, маркетинг, наука о данных и многих других.</p>
                </div>
                <img class="long-img-mobile-human" src="@/assets/img/man.png" alt="">
              </div>
            </router-link>
          </swiper-slide>
          <swiper-slide>
            <router-link to="/program/it-security">
              <img class="w-100 long-img-main" src="@/assets/img/main_three2.png" alt="">
              <div class="long-img-mobile">
                <div class="icons">
                  <div class="inner_icons"><span>Магистратура</span></div>
                  <div class="inner_icons"><span>Бакалавр</span></div>
                </div>
                <div class="title">
                  <h3>Информационная безопасность </h3>
                  <p>Программа Data Science открывает перед выпускниками множество карьерных возможностей в таких областях как IT, финансы, здравоохранение, маркетинг, наука о данных и многих других.</p>
                </div>
                <img class="long-img-mobile-human" src="@/assets/img/woman.png" alt="">
              </div>
            </router-link>
          </swiper-slide>

          <!-- Навигационные стрелки -->
          <div ref="prev2" class="swiper-button-next"></div>
          <div ref="next2" class="swiper-button-prev"></div>
        </swiper>
      </div>
    </div>

    <div class="wrapper_four container py-5 mt-3" id="profession">
      <div class="title">
        <h1>Выбери профессию по душе</h1>
        <hr>
      </div>
      <p class="py-3">После завершения обучения, вы сможете определиться с профессией для своего будущего.</p>
      <div class="inner_content">
        <div class="inner_card">
          <p>IT-специалист</p>
        </div>
        <div class="inner_card">
          <p>Web-программист</p>
        </div>
        <div class="inner_card">
          <p>Администратор баз данных</p>
        </div>
        <div class="inner_card">
          <p>Бизнес-аналитик</p>
        </div>
        <div class="inner_card">
          <p>Менеджер интернет-проектов</p>
        </div>
        <div class="inner_card">
          <p>Менеджер проекта</p>
        </div>
        <div class="inner_card">
          <p>Программист</p>
        </div>
        <div class="inner_card">
          <p>Разработчик программного обеспечения</p>
        </div>
        <div class="inner_card">
          <p>Продакт-менеджер</p>
        </div>
        <div class="inner_card">
          <p>Системный администратор</p>
        </div>
        <div class="inner_card">
          <p>Разработчик Web и мультимедийных приложений</p>
        </div>
        <div class="inner_card">
          <p>Специалист по информационным ресурсам</p>
        </div>
        <div class="inner_card">
          <p>Специалист по информационным системам</p>
        </div>
      </div>

      <Partners/>

      <div class="mobile">
        <div class="logos">
          <div class="logos-slide">
            <img src="@/assets/img/logo1.png" alt="Logo 1">
            <img src="@/assets/img/logo2.png" alt="Logo 2">
            <img src="@/assets/img/logo3.png" alt="Logo 2">
            <img src="@/assets/img/logo4.png" alt="Logo 2">
          </div>
        </div>
      </div>
    </div>

    <ContactForm/>
  </div>

</template>

<script>
import { ref, onMounted } from 'vue';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import ContactForm from "../../../components/ru/ContactForm";
import Partners from "../../../components/ru/Partners";

export default {
  name: "landingPage",
  components: {
    ContactForm,
    Swiper,
    SwiperSlide,
    Partners
  },
  data() {
    return {
      logos: [
        [
          "~@/assets/img/Logo1.png",
          "~@/assets/img/Logo2.png",
          "~@/assets/img/Logo3.png",
          "~@/assets/img/Logo4.png",
        ],
        [
          "~@/assets/img/Logo1.png",
          "~@/assets/img/Logo2.png",
          "~@/assets/img/Logo3.png",
          "~@/assets/img/Logo4.png",
        ]
      ],
    };
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    const prev1 = ref(null);
    const next1 = ref(null);
    const prev2 = ref(null);
    const next2 = ref(null);

    onMounted(() => {
      const swiper1 = document.querySelector('.mySwiper1').swiper;
      swiper1.params.navigation.prevEl = prev1.value;
      swiper1.params.navigation.nextEl = next1.value;
      swiper1.navigation.init();
      swiper1.navigation.update();

      const swiper2 = document.querySelector('.mySwiper2').swiper;
      swiper2.params.navigation.prevEl = prev2.value;
      swiper2.params.navigation.nextEl = next2.value;
      swiper2.navigation.init();
      swiper2.navigation.update();
    });

    return {
      onSwiper,
      onSlideChange,
      prev1,
      next1,
      prev2,
      next2,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import './landing.scss';
</style>