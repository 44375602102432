<template>
  <div id="landing">
    <div class="wrapper w-100 container mb-5 mt-3 m-auto d-flex align-items-center">
      <img class="main" src="@/assets/img/banners/mkm.png" alt="">
      <img class="mobile" src="@/assets/img/mainmobile.png" alt="">
      <div class="title">
        <p class="top_edu">
          <span>Магистратура</span>
          <span>Бакалавриат</span>
        </p>
        <h3>Математикалық және<br/>компьютерлік модельдеу</h3>
        <p style="border-bottom: 2px solid #FFA51E;width:80px;">6B06103</p>
        <router-link to="#application"><button>Кеңес алу</button></router-link>
      </div>
    </div>
    <div class="container px-5">
      <p><b class="blue_bold">Бағдарлама</b> математикалық және компьютерлік модельдеу саласында күрделі тапсырмаларды шеше алатын білікті мамандарды даярлауға бағытталған. Бағдарламаның мақсаты – студенттерге математика, есептеу технологиялары және деректерді талдау бойынша терең білім беру, сондай-ақ осы білімді әртүрлі ғылым және бизнес салаларында негізделген шешімдер қабылдауға көмектесетін модельдер құру мен талдау үшін қолдану дағдыларын дамыту.</p>

      <p><b class="blue_bold">Кәсіби қызметтің міндеттері:</b></p>
      <ul>
        <li>Математикалық модельдерді әзірлеу және талдау: Ғылым, техника және экономикадағы күрделі процестерді сипаттау және болжау үшін модельдерді құру, талдау және оңтайландыру.</li>
        <li>Бағдарламалау және алгоритмдік қамтамасыз ету: Математикалық модельдер мен алгоритмдерді іске асыру үшін бағдарламалық жасақтаманы әзірлеу, бағдарламалау, тестілеу және түзету.</li>
        <li>Деректерді талдау және визуализация: Үлкен көлемдегі деректерді өңдеу, шешім қабылдауды қолдау үшін статистикалық талдау және визуализация әдістерін пайдалану.</li>
        <li>Жаңа әдістерді зерттеу және енгізу: Математикалық модельдеу саласындағы ең жаңа әдістер мен технологияларды зерттеу және оларды практикалық тапсырмаларда қолдану.</li>
        <li>Пәнаралық ынтымақтастық: Кешенді шешімдерді әзірлеу және модельдерді жетілдіру үшін басқа салалардың мамандарымен топта жұмыс істеу.</li>
      </ul>

      <p><b class="blue_bold">Жұмысқа орналасу мүмкіндіктері:</b></p>
      <ul>
        <li>Деректерді талдау мамандары: Үлкен деректерді өңдеу және талдау, қаржылық және инвестициялық талдаулармен айналысатын компанияларда жұмыс істеу.</li>
        <li>Бағдарламалық жасақтама әзірлеушілері: Математикалық модельдеу және алгоритмдерді пайдаланатын бағдарламалық шешімдерді жасау және қолдау.</li>
        <li>Модельдеу және симуляция инженерлері: Машина жасау, аэроғарыш өнеркәсібі және басқа салалардағы инженерлік тапсырмаларды шешу үшін математикалық модельдерді қолдану.</li>
        <li>Ғылыми зерттеушілер: Академиялық және зерттеу институттарында зерттеулер жүргізу, модельдеу мен талдаудың жаңа әдістерін әзірлеу.</li>
        <li>Математикалық модельдеу бойынша консультанттар: Бизнес-процестерді оңтайландыру және шешім қабылдау үшін модельдерді әзірлеу және қолдану мәселелері бойынша ұйымдарға кеңес беру.</li>
        <li>Қаржы саласының мамандары: Қауіптерді талдау, инвестициялық жобаларды бағалау және қаржылық модельдерді әзірлеу.</li>
      </ul>

      <p>Математикалық және компьютерлік модельдеу бойынша бакалавриат бағдарламасы студенттерге терең теориялық білім мен практикалық дағдыларды ұсынады, бұл модельдеу әдістерін кәсіби қызметтің ең әртүрлі салаларында тиімді қолдануға мүмкіндік береді.</p>
    </div>
    <div class="wrapper_four container py-5 mt-3" id="profession" style="height: auto;">
      <Partners/>
    </div>
    <ContactForm/>
  </div>
</template>

<script>
  import ContactForm from "../../../../components/kz/ContactForm";
  import Partners from "../../../../components/kz/Partners";

  export default {
    name: "MathCompModelingPage",
    components: {
      ContactForm,
      Partners
    },
    data(){
    },
    methods: {

    }
  }
</script>

<style lang="scss">
@import './math-comp-modeling.scss';
@import '../../landing/landing.scss';
</style>