<template>
  <div id="app">
    <component :is="Header" />
    <router-view></router-view>
    <component :is="Footer" />
    <component :is="WhatsApp" />
  </div>
</template>

<script>
import { defineComponent, defineAsyncComponent, computed, watch, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();

    // Определяем язык на основе маршрута
    const lang = computed(() => (route.path.includes("/kz/") ? "kz" : "ru"));

    // Функция для динамической загрузки компонентов
    function loadComponent(name, currentLang) {
      return defineAsyncComponent(() =>
          import(`@/components/${currentLang}/${name}.vue`)
      );
    }

    // Реактивные ссылки на компоненты
    let Header = computed(() => loadComponent("Header", lang.value));
    let Footer = computed(() => loadComponent("Footer", lang.value));
    let WhatsApp = computed(() => loadComponent("WhatsApp", lang.value));

    // Слежение за изменением языка и обновление компонентов
    watch(lang, (newLang) => {
      Header = computed(() => loadComponent("Header", newLang));
      Footer = computed(() => loadComponent("Footer", newLang));
      WhatsApp = computed(() => loadComponent("WhatsApp", newLang));
    });

    // Логика прокрутки по хэшу
    const scrollIfHashRoute = () => {
      if (route.hash.length > 0) {
        const element = document.querySelector(route.hash);
        if (element) {
          const positionHeight = element.getBoundingClientRect();
          window.scrollTo(0, positionHeight.top + window.scrollY);
        }
      }
    };

    // Прокрутка при монтировании компонента
    onMounted(() => {
      scrollIfHashRoute();
    });

    // Слежение за изменением хэша
    watch(
        () => route.hash,
        () => {
          scrollIfHashRoute();
        }
    );

    // Обработка кликов по `router-link` для плавной прокрутки
    document.addEventListener("click", (event) => {
      const link = event.target.closest("a.router-link-active");
      if (link) {
        const currentHash = route.hash;
        if (currentHash) {
          router.push({ hash: "" }).then(() => {
            router.push({ hash: currentHash });
          });
        } else {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      }
    });

    return { Header, Footer, WhatsApp };
  },
});
</script>

<style>
#app {
  font-family: Monrope, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
