<template>
  <div id="landing">
    <div class="wrapper w-100 container mb-5 mt-3 m-auto d-flex align-items-center">
      <img class="main" src="@/assets/img/banners/it-systems.png" alt="">
      <img class="mobile" src="@/assets/img/mainmobile.png" alt="">
      <div class="title">
        <h3>Қашықтықтан оқыту форматына көшу</h3>
        <router-link to="#application"><button>Кеңес алу</button></router-link>
      </div>
    </div>
    <div class="container px-5">
      <p>Төлем негізінде оқитын студенттер мен грант иегерлері үшін (грант сақталады), қашықтықтан оқыту форматына көшу (айына 30 000 теңге).<br /> &nbsp; &nbsp; &nbsp;<br /> <strong>Қашықтықтан оқытудың артықшылықтары:</strong></p>
      <ul>
        <li>Көлік шығындарын үнемдеу;</li>
        <li>Басқа қаладан келген студенттер үшін баспана жалдау шығындарын азайту;</li>
        <li>Оқуды жұмыс істеумен үйлестіру мүмкіндігі;</li>
        <li>Тамақтану шығындарын үнемдеу.</li>
      </ul>
      <div class="d-none">
        <p><strong>Нұсқаулық</strong></p>
        <ol>
          <li>30 000 теңге төлеу (ай сайын)</li>
        </ol>
        <p>1-нұсқа: <a href="https://pay.kaspi.kz/pay/8sh0l6pw">Төлем сілтемесі</a></p>
        <p>2-нұсқа: Kaspi QR</p>
        <img src="@/assets/img/ttdl-qr.gif" alt="">
        <ol start="2">
          <li>Өтінішті толтыру және қол қою &ndash; <a href="/theme/space/Obrazets-zayavleniya-na-GOG.docx">үлгіні жүктеу</a></li>
          <li>Өтініш пен төлем түбіртегін Төлеген Сабыржан Рысбекұлына Тіркеу кеңсесіне (ТК) тапсыру</li>
        </ol>
      </div>
      <div class="alert alert-danger">Қолданбалар қазір жабық</div>
    </div>
    <FeedBackForm/>
  </div>
</template>


<script>
  import FeedBackForm from "../../../../components/kz/FeedBackForm";

  export default {
    name: "TTDL",
    components: {
      FeedBackForm,
    },
    data(){
    },
    methods: {

    }
  }
</script>

<style lang="scss">
@import './ttdl.scss';
@import '../../landing/landing.scss';
</style>