<template>
  <div id="about">
    <div class="wrapper w-100 container mb-5 mt-3 m-auto d-flex align-items-center">
      <img class="main" src="@/assets/img/about_one.png" alt="">
      <img class="mobile" src="@/assets/img/mainmobile.png" alt="">
      <div class="title">
        <h3>ADWAY - сіздің болашағыңыз дәл осы жерде және қазір басталады</h3>
        <p>Бізбен бірге білімге, даму мен табысқа сапар шегіңіз!</p>
        <router-link to="/kz/about#application"><button>Кеңес алу</button></router-link>
      </div>
    </div>
    <div class="wrapper_one container py-5">
      <div class="inner_card py-5">
        <div class="inner_content">
          <h1>Тарих</h1>
          <p>Біздің онлайн-платформа "uchitelya.kz" бастапқы платформамыздан бастап ұзақ даму жолының нәтижесі болып табылады. Қазақстандағы білім беру ресурстарын басқару тәжірибеміз бізді жоғары сапалы білім беретін жаңа онлайн-платформаны құруға шабыттандырды.</p>
        </div>
        <div class="w-50">
          <img style="margin-left: auto;" src="@/assets/img/about_three.png" alt="">
        </div>
      </div>
      <div class="inner_card py-5">
        <div class="w-50">
          <img style="margin-left: auto;" src="@/assets/img/about_six.png" alt="">
        </div>
        <div class="inner_content">
          <h1>Миссия және Құндылықтар</h1>
          <p>Біз қолжетімді, сапалы және инновациялық білім беруді қамтамасыз етуді мақсат етеміз, болашақ көшбасшылар мен мамандарды дайындаймыз. Біздің құндылықтарымыз білімге ұмтылу, әртүрлілік және әр студенттің дамуына бағытталған.</p>
        </div>
      </div>
      <div class="inner_card py-5">
        <div class="inner_content">
          <h1>Білім беру бағдарламалары</h1>
          <p>Біздің платформа еңбек нарығының қазіргі заманғы талаптарына бейімделген кең ауқымды білім беру бағдарламаларын ұсынады. Біз тәжірибелі оқытушылар қолдайтын инновациялық курстарды таңдауға мүмкіндік беретінімізге мақтанамыз.</p>
        </div>
        <div class="w-50">
          <img style="margin-left: auto;" src="@/assets/img/about_seven.png" alt="">
        </div>
      </div>
      <div class="inner_card py-5" style="position:relative;">
        <div class="w-50">
          <img src="@/assets/img/about_4.png" alt="" style="position: absolute; top: -57px;">
        </div>
        <div class="inner_content">
          <h1>Инновациялар және Технологиялар</h1>
          <p>Біз оқытуды озық технологияларды пайдалана отырып жүзеге асырамыз, бірегей онлайн-ресурстарды, интерактивті сабақтарды және заманауи оқыту әдістерін ұсынамыз. Бұл біздің студенттеріміз үшін оқу процесінің максималды ыңғайлылығы мен тиімділігін қамтамасыз етеді.</p>
        </div>
      </div>
      <div class="inner_card py-5">
        <div class="inner_content">
          <h1>Қауымдастық және Желілік байланыс</h1>
          <p>Біз студенттер тәжірибе алмаса алатын, байланыстар орнатып, өз көкжиектерін кеңейте алатын білім беру қауымдастығын құрып жатырмыз. Біздің университет студенттер мен оқытушылар арасындағы өзара әрекеттесуді қолдай отырып, қоғамдық белсенділік пен шығармашылық идея алмасуына ықпал етеді.</p>
        </div>
        <div class="w-50">
          <img style="margin-left: auto;" src="@/assets/img/about_five.png" alt="">
        </div>
      </div>
    </div>
    <div class="wrapper_one_mobile container">
      <div class="inner_card py-2">
        <div class="inner_content">
          <h1>Тарих</h1>
          <p>Біздің онлайн-платформа "uchitelya.kz" бастапқы платформамыздан бастап ұзақ даму жолының нәтижесі болып табылады. Қазақстандағы білім беру ресурстарын басқару тәжірибеміз бізді жоғары сапалы білім беретін жаңа онлайн-платформаны құруға шабыттандырды.</p>
        </div>
        <div class="w-50">
          <img style="margin-left: auto;" src="@/assets/img/about_three_mobile.png" alt="">
        </div>
      </div>
      <div class="inner_card py-2">
        <div class="inner_content">
          <h1>Миссия және Құндылықтар</h1>
          <p>Біз қолжетімді, сапалы және инновациялық білім беруді қамтамасыз етуді мақсат етеміз, болашақ көшбасшылар мен мамандарды дайындаймыз. Біздің құндылықтарымыз білімге ұмтылу, әртүрлілік және әр студенттің дамуына бағытталған.</p>
        </div>
        <div class="w-50">
          <img style="margin-left: auto;" src="@/assets/img/about_six.png" alt="">
        </div>
      </div>
      <div class="inner_card py-2">
        <div class="inner_content">
          <h1>Білім беру бағдарламалары</h1>
          <p>Біздің платформа еңбек нарығының қазіргі заманғы талаптарына бейімделген кең ауқымды білім беру бағдарламаларын ұсынады. Біз тәжірибелі оқытушылар қолдайтын инновациялық курстарды таңдауға мүмкіндік беретінімізге мақтанамыз.</p>
        </div>
        <div class="w-50">
          <img style="margin-left: auto;" src="@/assets/img/about_seven.png" alt="">
        </div>
      </div>
      <div class="inner_card py-2" style="position:relative;">
        <div class="inner_content">
          <h1>Инновациялар және Технологиялар</h1>
          <p>Біз оқытуды озық технологияларды пайдалана отырып жүзеге асырамыз, бірегей онлайн-ресурстарды, интерактивті сабақтарды және заманауи оқыту әдістерін ұсынамыз. Бұл біздің студенттеріміз үшін оқу процесінің максималды ыңғайлылығы мен тиімділігін қамтамасыз етеді.</p>
        </div>
        <div class="w-50">
          <img src="@/assets/img/about_4.png" alt="" style="margin-left: auto;">
        </div>
      </div>
      <div class="inner_card py-2">
        <div class="inner_content">
          <h1>Қауымдастық және Желілік байланыс</h1>
          <p>Біз студенттер тәжірибе алмаса алатын, байланыстар орнатып, өз көкжиектерін кеңейте алатын білім беру қауымдастығын құрып жатырмыз. Біздің университет студенттер мен оқытушылар арасындағы өзара әрекеттесуді қолдай отырып, қоғамдық белсенділік пен шығармашылық идея алмасуына ықпал етеді.</p>
        </div>
        <div class="w-50">
          <img style="margin-left: auto;" src="@/assets/img/about_five.png" alt="">
        </div>
      </div>
    </div>
    <ContactForm/>
  </div>
</template>


<script>
  import ContactForm from "../../../components/kz/ContactForm";

  export default {
    name: "aboutPage",
    components: {
      ContactForm,
    },
    data(){
    },
    methods: {

    }
  }
</script>

<style lang="scss">
@import './about.scss';
</style>