<template>
  <div id="landing">
    <div class="wrapper w-100 container mb-5 mt-3 m-auto d-flex align-items-center">
      <img class="main" src="@/assets/img/banners/it-systems.png" alt="">
      <img class="mobile" src="@/assets/img/mainmobile.png" alt="">
      <div class="title">
        <p class="top_edu">
          <span>Магистратура</span>
          <span>Бакалавриат</span>
        </p>
        <h3>Computer science</h3>
        <p style="border-bottom: 2px solid #FFA51E;width:80px;">6B06102</p>
        <router-link to="#application"><button>Получить консультацию</button></router-link>
      </div>
    </div>
    <div class="container px-5">
      <p><b class="blue_bold">Программа</b> бакалавриата по Computer Science направлена на подготовку специалистов, обладающих глубокими знаниями в области компьютерных наук и технологий. Цель программы — предоставить студентам основательные навыки в программировании, разработке программного обеспечения, системном администрировании и управлении проектами, а также подготовить их к решению сложных задач в области информационных технологий и инновационных технологий.</p>

      <p><b class="blue_bold">Задачи профессиональной деятельности:</b></p>
      <ul>
        <li>Разработка программного обеспечения: Проектирование, кодирование, тестирование и поддержка программных систем и приложений, используя современные языки программирования и технологии разработки.</li>
        <li>Системное администрирование и управление IT-инфраструктурой: Настройка, поддержка и оптимизация работы серверов, сетей и баз данных, обеспечение безопасности информационных систем.</li>
        <li>Анализ и проектирование информационных систем: Сбор требований, разработка архитектуры и проектирование информационных систем для удовлетворения потребностей бизнеса и пользователей.</li>
        <li>Обработка и анализ данных: Разработка и применение методов анализа данных, работа с большими объемами данных и применение машинного обучения для выявления закономерностей и поддержки принятия решений.</li>
        <li>Управление проектами и разработка систем: Планирование, координация и управление проектами в области IT, включая разработку и внедрение новых технологий и систем.</li>
        <li>Исследование и внедрение новых технологий: Исследование новейших достижений в области компьютерных наук и внедрение их в практическую деятельность для создания инновационных решений.</li>
      </ul>



      <p><b class="blue_bold">Варианты трудоустройства:</b></p>
      <ul>
        <li>Разработчики программного обеспечения: Создание и поддержка приложений, систем и программных продуктов в различных отраслях, включая веб-разработку, мобильные приложения и корпоративные системы.</li>
        <li>Системные администраторы: Управление IT-инфраструктурой, настройка и поддержка серверов и сетевых систем в организациях и предприятиях.</li>
        <li>Аналитики данных: Обработка и анализ данных для предоставления бизнес-анализа и создания прогнозных моделей, работа с большими данными и машинным обучением.</li>
        <li>IT-консультанты: Консультирование компаний по вопросам внедрения и оптимизации информационных систем и технологий.</li>
        <li>Проектные менеджеры в IT: Управление проектами в области разработки и внедрения информационных систем, координация команд и управление ресурсами.</li>
        <li>Специалисты по кибербезопасности: Обеспечение защиты информационных систем и данных от угроз и атак, разработка и внедрение мер безопасности.</li>
      </ul>


      <p>Программа бакалавриата по Computer Science предоставляет студентам фундаментальные знания и практические навыки, которые позволяют им успешно работать в динамично развивающейся области информационных технологий и быть готовыми к вызовам и возможностям, которые предлагает современный рынок труда.</p>

    </div>
    <div class="wrapper_four container py-5 mt-3" id="profession" style="height: auto;">
      <Partners/>
    </div>
    <ContactForm/>
  </div>
</template>

<script>
  import ContactForm from "../../../../components/ru/ContactForm";
  import Partners from "../../../../components/ru/Partners";

  export default {
    name: "ComputerSciencePage",
    components: {
      ContactForm,
      Partners
    },
    data(){
    },
    methods: {

    }
  }
</script>

<style lang="scss">
@import './computer-science.scss';
@import '../../landing/landing.scss';
</style>