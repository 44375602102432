<template>
  <div id="landing">
    <div class="wrapper w-100 container mb-5 mt-3 m-auto d-flex align-items-center">
      <img class="main" src="@/assets/img/banners/it-systems.png" alt="">
      <img class="mobile" src="@/assets/img/mainmobile.png" alt="">
      <div class="title">
        <p class="top_edu">
          <span>Магистратура</span>
          <span>Бакалавриат</span>
        </p>
        <h3>Computer science</h3>
        <p style="border-bottom: 2px solid #FFA51E;width:80px;">6B06102</p>
        <router-link to="#application"><button>Кеңес алу</button></router-link>
      </div>
    </div>
    <div class="container px-5">
      <p><b class="blue_bold">Бағдарлама</b> «Computer Science» бакалавриаты компьютерлік ғылымдар мен технологиялар саласында терең білімге ие мамандарды даярлауға бағытталған. Бағдарламаның мақсаты – студенттерге бағдарламалау, бағдарламалық жасақтама әзірлеу, жүйелік әкімшілендіру және жобаларды басқару саласында негізгі дағдыларды үйрету және ақпараттық технологиялар мен инновациялық технологиялар саласындағы күрделі мәселелерді шешуге дайындау.</p>

      <p><b class="blue_bold">Кәсіби қызметтің міндеттері:</b></p>
      <ul>
        <li>Бағдарламалық жасақтама әзірлеу: Заманауи бағдарламалау тілдері мен әзірлеу технологияларын пайдалана отырып, бағдарламалық жүйелер мен қосымшаларды жобалау, кодтау, тестілеу және қолдау.</li>
        <li>Жүйелік әкімшілендіру және IT инфрақұрылымын басқару: Серверлер, желілер мен дерекқорларды баптау, қолдау және оңтайландыру, ақпараттық жүйелердің қауіпсіздігін қамтамасыз ету.</li>
        <li>Ақпараттық жүйелерді талдау және жобалау: Талаптарды жинау, архитектураны әзірлеу және пайдаланушылар мен бизнестің қажеттіліктерін қанағаттандыру үшін ақпараттық жүйелерді жобалау.</li>
        <li>Деректерді өңдеу және талдау: Деректерді талдау әдістерін әзірлеу және қолдану, үлкен деректермен жұмыс істеу және шешім қабылдауды қолдау үшін машиналық оқытуды қолдану.</li>
        <li>Жобаларды басқару және жүйелерді әзірлеу: IT саласында жаңа технологиялар мен жүйелерді әзірлеу және енгізу жобаларын жоспарлау, үйлестіру және басқару.</li>
        <li>Жаңа технологияларды зерттеу және енгізу: Компьютерлік ғылымдар саласындағы соңғы жетістіктерді зерттеу және инновациялық шешімдерді жасау үшін оларды тәжірибелік қызметке енгізу.</li>
      </ul>

      <p><b class="blue_bold">Жұмысқа орналасу мүмкіндіктері:</b></p>
      <ul>
        <li>Бағдарламалық жасақтама әзірлеушілер: Әртүрлі салаларда, соның ішінде веб-әзірлеу, мобильді қосымшалар және корпоративтік жүйелерде қосымшалар, жүйелер және бағдарламалық өнімдерді жасау және қолдау.</li>
        <li>Жүйелік әкімшілер: IT инфрақұрылымын басқару, ұйымдар мен кәсіпорындардағы серверлер мен желілік жүйелерді баптау және қолдау.</li>
        <li>Деректер аналитиктері: Деректерді өңдеу және талдау, бизнес-талдау ұсыну және болжамдық модельдер жасау, үлкен деректермен және машиналық оқытумен жұмыс істеу.</li>
        <li>IT кеңесшілері: Ақпараттық жүйелер мен технологияларды енгізу және оңтайландыру мәселелері бойынша компанияларға кеңес беру.</li>
        <li>IT жобаларының менеджерлері: Ақпараттық жүйелерді әзірлеу және енгізу жобаларын басқару, командаларды үйлестіру және ресурстарды басқару.</li>
        <li>Киберқауіпсіздік мамандары: Ақпараттық жүйелер мен деректерді қауіп-қатерлер мен шабуылдардан қорғауды қамтамасыз ету, қауіпсіздік шараларын әзірлеу және енгізу.</li>
      </ul>

      <p>«Computer Science» бакалавриат бағдарламасы студенттерге ақпараттық технологиялар саласында табысты жұмыс істеуге мүмкіндік беретін негізгі білімдер мен практикалық дағдыларды береді және заманауи еңбек нарығы ұсынатын қиындықтар мен мүмкіндіктерге дайын болуға көмектеседі.</p>
    </div>
    <div class="wrapper_four container py-5 mt-3" id="profession" style="height: auto;">
      <Partners/>
    </div>
    <ContactForm/>
  </div>
</template>


<script>
  import ContactForm from "../../../../components/kz/ContactForm";
  import Partners from "../../../../components/kz/Partners";

  export default {
    name: "ComputerSciencePage",
    components: {
      ContactForm,
      Partners
    },
    data(){
    },
    methods: {

    }
  }
</script>

<style lang="scss">
@import './computer-science.scss';
@import '../../landing/landing.scss';
</style>